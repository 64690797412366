/**
 * Add option dateMin to the DateRangePickerMin library.
 *
 * @version 0.9
 * @author ThimPress
 */
;(function ($) {

    /**
     * Custom DateRangePicker
     *
     * @param el {DOM}
     * @param args {Object}
     * @constructor
     */
    function DateRangePickerMinDate(el, args) {

        var $el = $(el),
            datePicker = $el.data('daterangepicker'),
            $views = {},
            dateMin,
            selectedEnd;

        dateMin = parseInt(args.dateMin || 0);

        console.log(args)

        // If DateRangePicker is not applied to input or dateMin option is already added
        if (!datePicker || datePicker.dateMin) {
            return;
        }

        // Calculate the dateMin with the dateLimit option if it's set
        // dateMin always less than or equals with dateLimit
        if (datePicker.dateLimit && dateMin > datePicker.dateLimit.days) {
            dateMin = datePicker.dateLimit.days;
        }

        // If dateMin is less than 2 then no need to our code
        if (dateMin < 2) {
            return;
        }

        datePicker.dateMin = true;

        var customID = 'custom-date-min',
            availableDates = 0,

            /**
             * Highlight the date on the left or right calendar
             *
             * @param startDate
             * @param endDate
             * @param side
             */
            highlightDate = function (startDate, endDate, side) {
                var calendar = datePicker[side === 'left' ? 'leftCalendar' : 'rightCalendar'].calendar;

                // Reset to default if user has selected the end date
                if (selectedEnd) {
                    $.each(['left', 'right'], function () {
                        $views[this].find('td').removeClass('date-min no-click')
                    });

                    return;
                }

                /**
                 * Loop through all cells in calendar and highlight the dates user can not select
                 */
                for (var row = 0; row < 6; row++) {
                    for (var col = 0; col < 7; col++) {
                        var d = calendar[row][col],
                            $date;

                        if (d.isSame(startDate) || d.isSame(endDate) || d.isAfter(startDate) && d.isBefore(endDate)) {
                            $date = $views[side].find('[data-title="r' + row + 'c' + col + '"]').addClass('in-range date-min');
                        } else {
                        }

                        if ($date && $date.hasClass('available')) {
                            availableDates++;
                            if (availableDates < dateMin) {
                                $date.addClass('no-click');
                            }
                        }
                    }
                }
            },

            /**
             * Highlight the dates in range
             */
            highlightDatesRange = function () {
                $views[this] = datePicker.container.find('.calendar.' + this);
                $views[this].off('mouseup.' + customID).on('mouseup.' + customID, function (e) {
                    var $startDate = datePicker.container.find('.start-date'),
                        $endDate = datePicker.container.find('.end-date'),
                        side = $startDate.closest('.calendar').hasClass('left') ? 'left' : 'right',
                        calendar = datePicker[side === 'left' ? 'leftCalendar' : 'rightCalendar'].calendar,
                        title = $startDate.attr('data-title'),
                        curRow = title.substr(1, 1),
                        curCol = title.substr(3, 1),
                        startDate = calendar[curRow][curCol],
                        endDate = startDate.clone().add(dateMin - 1, 'day');

                    selectedEnd = !!$endDate.length;
                    availableDates = 0;

                    highlightDate(startDate, endDate, 'left');
                    highlightDate(startDate, endDate, 'right');
                });
            };
        $el.attr('data-date-min', dateMin).data('dateMin', dateMin);

        $.each(['left', 'right'], highlightDatesRange);
    }

    // jQuery plugin
    $.fn.DateRangePickerMinDate = function (args) {
        return $.each(this, function () {
            var _args = $.extend(args, $(this).data());
            $(this).on('focus', function () {
                new DateRangePickerMinDate(this, $.extend({dateMin: ''}, _args));
            });
        });
    }
})(jQuery);