(function ($) {
	"use strict";
	$(window).load(function () {
		var items = $('.thim-sc-hb-rooms-slider').data('items');

		var thimpress_hotel_booking_carousel = $('.thim-sc-hb-rooms-slider .thim_hb_room_carousel .rooms');
		console.log(thimpress_hotel_booking_carousel);
		thimpress_hotel_booking_carousel.owlCarousel({
			nav            : true,
			dots           : false,
			items          : items,
			paginationSpeed: 600,
			slideSpeed     : 600,
			autoPlay       : true,
			stopOnHover    : true
		});
	});
})(jQuery);